<template>
  <b-container fuild style="max-width: 2560px;">
    <b-row>
      <b-col lg="12" class="row-eq-height">
        <filtering-title 
          @save='updateServiceConfiguration'
          @refresh='refresh'
          :disableSubmit='disableSubmit'
          :loading='loading'
          :updating='updating' />
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12" xl="6" class="entranceFromLeft row-eq-height" style="flex-direction: column;">
        <filtering-configuration 
          :filtering-configuration='filteringConfiguration'
          />       
      </b-col>      
    </b-row>
    <confirmation-modal ref='confirmation-modal'/>
  </b-container>
</template>

<script>
import FilteringTitle from './FilteringTitle.vue';
import FilteringConfiguration from './FilteringConfiguration.vue';
import ConfirmationModal from "@/components/Modals/ConfirmationModal.vue";

export default {
  name: "DNScfAdvanced",
  components: {
    FilteringTitle,
    FilteringConfiguration,    
    ConfirmationModal,
  },
  data() {
    return {
      filteringConfiguration: {
        enableSafeSearch: false,
        enableQuietTime: false,
      },
      original : [],
      loading: false,    
      updating: false,
    };
  },
  computed: {
    disableSubmit() {
      return false;
    },
  },
  methods: {        
    async refresh() {      
      const isConfirmed = await this.$refs['confirmation-modal'].open({
        title: this.t('refreshTitle'),
        message: this.t('refreshAlert'),
      });
      if (isConfirmed) { 
        this.setServiceConfig();
      }
    },

    async updateServiceConfiguration() {      
      this.loading = true;

      const isConfirmed = await this.$refs['confirmation-modal'].open({
         title: this.t('saveChanges'),
         message: this.t('confirmConfigurationChanges'),
      });

      if (!isConfirmed) { return; }

      const { webfilter } = await this.$store.getters['dnssecure/dnsstatus/dnsstatus']
      this.$data.original["enable_safe_search"] = this.filteringConfiguration.enableSafeSearch
      this.$data.original["enable_quiet_time"] = this.filteringConfiguration.enableQuietTime        
      
      await this.$store.dispatch('dnssecure/dnsstatus/updateDNSState', this.$data.original)
      /*.then( res => {               
          this.refresh(0)
      })
      .catch( err => {  
          this.refresh(0)                      
          console.log("error:" + err)
      });*/      
      this.loading = false;
    },
    
    async setServiceConfig() {
      this.updating = true;
      await this.$store.dispatch('dnssecure/dnsstatus/getDNSState') .then( res => {    
          this.$data.original = _.cloneDeep(res)
          Object.assign(this.filteringConfiguration, {                
            enableSafeSearch: this.$data.original.enable_safe_search,                
            enableQuietTime: this.$data.original.enable_quiet_time,                
          });     
      });      
      this.updating = false;      
    }
  },
  created() {
    this.setServiceConfig();
  },
}
</script>
